import API from "@/api";

export default {
  get: () => {
    return API.requestPost({ url: "basic/selectBasic" });
  },
  send: params => {
    return API.requestPost({
      url: "basic/updateBasic",
      data: params
    });
  },
  getZf: params => {
      return API.requestPost({
          url:'basic/query',
          data: params
      })
  },
  save:params => {
    return API.requestPost({
        url:'basic/update',
        data: params
    })
  }, 
};
