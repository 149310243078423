<template>
  <div class="user-box">
    <div class="form-block">
      <el-divider direction="vertical" class="line"></el-divider>
      <span class="ft-sz-14 ft-medium">基础设置</span>
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        class="register"
        label-width="120px"
        hide-required-asterisk
      >
        <el-form-item label="商户名称" prop="name">
          <el-input type="text" v-model.trim="form.name" placeholder="请输入商户名称"></el-input>
        </el-form-item>
        <el-form-item label="放款密码" prop="password" v-if="showPasswordItem">
          <el-input
            type="text"
            v-model.trim="form.password"
            :placeholder="hasPassword ? '*******' : '请输入放款密码'"
          ></el-input>
        </el-form-item>
        <el-form-item label="银行卡实名姓名" prop="merchant_bank_name" v-if="showPasswordItem">
          <el-input placeholder="请输入实名姓名" v-model="form.merchant_bank_name"></el-input>
        </el-form-item>
        <el-form-item label="银行卡卡号" prop="merchant_bank_code" v-if="showPasswordItem">
          <el-input placeholder="请输入银行卡卡号" v-model="form.merchant_bank_code"></el-input>
        </el-form-item>
         <el-form-item label="微信收款码" v-if="showPasswordItem">
              <div class="pic_list_box">
                <div class="pic_list" v-if="wechatQrCodeImg">
                  <img :src="wechatQrCodeImg" alt srcset />
                </div>
                <i class="el-icon-upload" v-else></i>
                <input
                  type="file"
                  accept="jpg, png, gif"
                  @change="changeWechatImage($event)"
                  ref="wechatQrCodeInput"
                  style="opacity: 0;
                      z-index: 10;
                      position: absolute;
                      height: 160px;
                      width: 100%;"
                />
              </div>
            </el-form-item>
            <el-form-item label="支付宝收款码" v-if="showPasswordItem">
              <div class="pic_list_box">
                <div class="pic_list" v-if="alipayQrCodeImg">
                  <img :src="alipayQrCodeImg" alt srcset />
                </div>
                <i class="el-icon-upload" v-else></i>

                <input
                  type="file"
                  accept="jpg, png, gif"
                  @change="changeAlipayImage($event)"
                  ref="alipayQrCodeInput"
                  style="opacity: 0;
                      z-index: 10;
                      position: absolute;
                      height: 160px;
                      width: 100%;"
                />
              </div>
            </el-form-item>

        <el-form-item>
          <el-button class="btn" type="primary" @click="submit">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
// components
import service from './api';
import { base, baseUrl } from '@/api/env';

export default {
  data() {
    return {
      form: {name: '', password: '', merchant_bank_name: '', merchant_bank_code: ''},
      rules: {
        password: [
          {pattern: /^[a-zA-z0-9]{6,15}$/, message: '6-16位字符，由数字、字母组成'},
           { required: true, message: "请填写放款密码" },
        ],
        name: [
          {pattern: /^[a-zA-z0-9\u4e00-\u9fa5]{1,7}$/, message: '1-7位字符，由数字、字母、中文组成'},
           {required: true, message: '请填写商户名称'}
        ],
        merchant_bank_name: [
          {message: '请输入姓名', trigger: 'blur'},
          {min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur'}],
        merchant_bank_code: [
          {pattern: /^([1-9]{1})(\d{15}|\d{18})$/, message: '请输入正确的银行卡号'}
        ]
      },
      hasPassword: false,
      showPasswordItem: false,
      alipayQrCode: null,
      alipayQrCodeImg: null,
      wechatQrCode: null,
      wechatQrCodeImg: null,
    };
  },
  components: {},
  methods: {
    // 更新
    submit() {
      let checkPsd = true;
      let checkName = true;
      this.$refs.form.validateField(['name'], err => (checkName = !err));
      // this.form.password && this.$refs.form.validateField(['password'], err => (checkPsd = !err));
      !this.hasPassword && this.$refs.form.validateField(['password'], err => (checkPsd = !err))
      if (!checkPsd || !checkName) return false;
      let passData = {
        name: this.form.name,
        password: this.form.password,
      }
      if (this.showPasswordItem) {
        console.log(this.wechatQrCode, this.alipayQrCode)
        if (!this.alipayQrCode && !this.wechatQrCode && !(this.form.merchant_bank_name && this.form.merchant_bank_code)) {
          this.$notify({type: 'error', message: '请完成一种收款方式！', offset: 400});
          return false
        }
      }
      let ZfData = {
        merchant_bank_name: this.form.merchant_bank_name,
        merchant_bank_code: this.form.merchant_bank_code,
        merchant_alipay_pay_qrcode: this.alipayQrCode,
        merchant_wechat_pay_qrcode: this.wechatQrCode,
      }
      return service.send({...passData}).then(res => {
        return service.save({...ZfData}).then(res => {
          this.$notify({type: 'success', message: '更新成功！', offset: 400});
          this.get();
          this.getZf();
          sessionStorage.setItem('merchant_name', this.form.name);
          // location.reload();
        })
      });
    },
    changeWechatImage(e) {
      // 上传图片事件
      var files = this.$refs.wechatQrCodeInput.files;
      let param = new FormData();
      param.append("file", files[0]);
      this.$axios
        .post(base + "api/common/attach", param, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(res => {
          this.wechatQrCode = res.data.id
          this.wechatQrCodeImg = res.data.url
        })
        .catch(err => {
          // return reject(err);
        });
    },
    changeAlipayImage(e) {
      // 上传图片事件
      var files = this.$refs.alipayQrCodeInput.files;
      let param = new FormData();
      param.append("file", files[0]);
      this.$axios
        .post(base + "api/common/attach", param, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(res => {
          this.alipayQrCode = res.data.id
          this.alipayQrCodeImg = res.data.url
        })
        .catch(err => {
          // return reject(err);
        });
    },
    get() {
      return service.get().then(res => {
        this.form.name = res.merchantName;
        this.form.password = '';
        this.hasPassword = res.fkpw;
        this.showPasswordItem = res.fkpwType;
      });

    },
    getZf(){
      return service.getZf({keys:'merchant_bank_code,merchant_bank_name,merchant_wechat_pay_qrcode,merchant_alipay_pay_qrcode'}).then(res => {
        this.form.merchant_bank_name = res.merchant_bank_name
        this.form.merchant_bank_code  = res.merchant_bank_code
        this.alipayQrCode = res.merchant_alipay_pay_qrcode
        this.wechatQrCode = res.merchant_wechat_pay_qrcode
        this.alipayQrCodeImg = base+'api/common/attach/'+res.merchant_alipay_pay_qrcode
        this.wechatQrCodeImg = base+'api/common/attach/'+res.merchant_wechat_pay_qrcode
      });
    }
  },
  mounted() {
    this.getZf()
    this.get();
  }
};
</script>

<style lang="scss" scoped>
.user-box {
  min-height: 100%;
  .register {
    width: 50%;
    .el-input {
      width: 300px;
    }
    .el-button {
      width: 300px;
      letter-spacing: 4px;
      font-size: 16px;
    }
  }
  .pic_list_box {
    display: flex;
    border: 1px dashed #d9d5d5;
    // background: url('../../');
    position: relative;
    height: 160px;
    width: 200px;
    .upload_btn {
      width: 80px;
      height: 80px;
      padding-left: 15px;
    }
    .el-icon-upload{
      font-size: 32px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left:-16px;
      margin-top: -16px;
    }
    .pic_list {
      margin: 4px auto;
      img {
        width: 160px;
        height: 160px;
        padding-bottom: 10px;
        margin: 0 auto;
      }
    }
  }
  .form-block {
    text-align: left;
    .line {
      width: 2px;
      background-color: #2b65b1;
    }
    .el-form {
      margin-top: 30px;
    }
  }
}
@media screen and (max-width: 480px) {
  .el-input {
      max-width: 10rem;
    }
    .btn{
      width: 7rem!important;
    }
}
</style>
